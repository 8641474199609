/* eslint-disable prettier/prettier */
import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Musweu Munyima', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my webspace on the internet', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Musweu Munyima',
  subtitle1: "I'm a ",
  subtitle: 'Software Developer.',
  cta: 'Find Out More',
};

// ABOUT DATA
export const aboutData = {
  // img: 'profile.jpg',
  paragraphOne:
    'I enjoy learning and striving to do better than before and i love what i do because coding gives me the ability to bring my imagination to life and put it out on the internet for the world to see.',
  paragraphTwo:
    'I am quite comfortable in PHP, Flutter/Dart, Javascript, React Js, Python and C#. I can work with Git, Gitbash, Intellij, XAMPP, Pycharm, GitHub, MySQL, Postman, MongoDb, Node js, Bootsrap, Materialize and much more. ',
  paragraphThree: 'I am currently learning Ruby and Java.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'weather pic1.png',
    title: 'MusWeather',
    info: "This is a simple weather web application that i created using React JS and Open Weather Map's Api",
    info2:
      'it was one of my first React JS projects that i had created and i really enjoyed working on it, you can see it Live using the Link below.',
    url: 'https://musweather.netlify.app/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'ebaydesing1.png',
    title: 'Ebay Mobile App UI',
    info: 'This is one of my mobile UI application projects that i ceated using the  Flutter FrameWork and Dart Language.',
    info2:
      'I created screens such as the Splash Screen, Home Screen, MenuDrawer, Details Screen and the Recently Viewed Screen ',
    url: 'https://github.com/Musweu10/ebay_clone',
    repo: 'https://github.com/Musweu10/ebay_clone', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'notespace.png',
    title: 'Note Space',
    info: 'Notes Space is a React JS Web Application that i created to learn how to cache notes in the browsers cache storage and retrive the notes when the user revisites the site.',
    info2:
      'it is mobile responsive and has what almost every developer loves which is a dark mode option, you can visit the site and the source code by clicking on the image or using the links below.',
    url: 'https://notes-space.vercel.app/',
    repo: 'https://github.com/Musweu10/notes-space', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'estore.png',
    title: 'Estore',
    info: 'Estore  is another React JS Web Application that i created to learn how to handle add to cart functionality and intergrating paypal as a payment service.',
    info2:
      ' I recently disabled paypal because it is just the sand box account and no real items are available for purches but, you can visit the site and the source code by clicking on the image or using the links below.',
    url: 'https://estoresite.netlify.app/',
    repo: 'https://github.com/Musweu10/Ecommerce-store', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'colorchangerpic.png',
    title: 'RGB Colour Game',
    info: 'This is an RGB Colour game that i created using Vanilla Javascript it was one of my first JS projects and really made me challenge myself.',
    info2:
      'It is fully Mobile responsive and helps keep your "Colour Game" sharp feel, free to visit the site using the link below.',
    url: 'https://rgbcolorgame10.netlify.app/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to work with me? Awesome!',
  btn: 'Lets Get In touch',
  email: 'musweumunyima@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://mobile.twitter.com/_mus10',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/musweu10/',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/musweu-munyima-207a2a18b',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/Musweu10',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
